<template>
  <PostUpsertForm />
</template>
<script>
import axios from 'axios';
import PostUpsertForm from '../components/post/PostUpsertForm.vue';

export default {
  name: 'Dashboard',
  components: { PostUpsertForm },
  data() {
    const defaultForm = Object.freeze({
      title: 'axios',
      content: 'axios',
      link: 'lnink',
      category: '프로젝트',
    });

    return {
      form: Object.assign({}, defaultForm),
      rules: {
        category: [(val) => (val || '').length > 0 || '필수입력'],
        title: [(val) => (val || '').length > 0 || '필수입력'],
        content: [(val) => (val || '').length > 0 || '필수입력'],
        link: [(val) => (val || '').length > 0 || '링크 주소를 확인하세요'],
      },
      categories: ['비디오 컨텐츠', '크레파스 워십'],
      snackbar: false,
      defaultForm,
    };
  },

  computed: {
    formIsValid() {
      return this.form.title && this.form.content && this.form.category;
    },
  },

  methods: {
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async submit() {
      await axios
        .post(
          'https://shrouded-headland-21000.herokuapp.com/api/posts',
          {
            title: this.form.title,
            content: this.form.content,
            youtubeUrl: this.form.link,
            category: this.form.category,
          },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .then((this.snackbar = true), this.resetForm())
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
